var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center mt-10" },
    [_c("v-card", { attrs: { flat: "", shaped: "" } }, [_c("v-card-text")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }