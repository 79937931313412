<template>
  <div class="text-center mt-10">
    <v-card flat shaped>
      <v-card-text> </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'sinavlar-sayfasi',
  data() {
    return {
      results: [],
      fields: [
        { key: 'Id', label: '#' },
        { key: 'Exam.Name', label: 'Sınav', sortable: false },
        { key: 'Student.FullName', label: 'Öğrenci', sortable: false },
        { key: 'TotalCorrectCount', label: 'Doğru', sortable: false },
        { key: 'TotalWrongCount', label: 'Yanlış', sortable: false },
        { key: 'TotalEmptyCount', label: 'Boş', sortable: false },
        { key: 'TotalNet', label: 'Net', sortable: false }
      ],
      count: 5,
      loading: true,
      options: {}
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM YYYY HH:mm') : ''
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamSessionStudentLessonResult', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          IsStudent: true
        }
      })
        .then((data) => {
          this.results = data.data.Results
          this.totalRows = data.data.TotalNumberOfRecords
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
